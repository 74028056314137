import { useQuery } from '@tanstack/react-query'
import { getPositionalPlot, postProteinPlots } from '../client/apiClient'

const usePeptides = (searchType: string, searchTerm: string, hlaFilter: string) => {
  return useQuery([ 'proteinPlots', searchType, searchTerm, hlaFilter ], () => postProteinPlots(searchTerm, hlaFilter))
}

export const usePositionalPlot = (proteinId: string, hlaFilter: string) => {
  return useQuery([ 'positionalPlot', hlaFilter ], () => getPositionalPlot(proteinId, hlaFilter))
}

export default usePeptides