import ResultType from 'src/types/ResultType'
import Scrollbar from 'src/components/Scrollbar'
import SampleInformationPeptideListTable from 'src/components/dialogs/SampleInformationPeptideListTable'
import { ResultTypeContext } from '../contexts/context'

function SampleInformationPeptideList() {
  return (
    <Scrollbar>
      <ResultTypeContext.Provider value={ResultType.SamplesListResult}>
        <div className='mr-4 h-full w-full px-4 pb-4'>
          <div className='mt-8 flex w-full max-w-screen-xl flex-col 2xl:max-w-screen-2xl'>
            <h4 className='mt-8'>Peptide List</h4>
            <SampleInformationPeptideListTable />
          </div>
        </div>
      </ResultTypeContext.Provider>
    </Scrollbar>
  )
}

export default SampleInformationPeptideList
