import { CSVLink } from 'react-csv'

type Props = {
  data: any[]
  filename: string
}

const CsvDownloadButton = ({ data, filename }: Props) => {

  if (!data || data.length === 0) return null

  return <CSVLink className='button button-inline button-primary--active' data={data} filename={filename}>Download data as CSV</CSVLink>
}

export default CsvDownloadButton