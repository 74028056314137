import Loading from '../Loading'
import React, { useEffect, useId, useState } from 'react'
import usePeptides from '../../hooks/usePeptides'
import NoResultMessageBox from '../NoResultMessageBox'
import CsvDownloadButton from '../offtarget/CsvDownloadButton'
import { errorHandler } from '../../client/apiClient'
import { DataTable } from '../tables/DataTable'
import useDialogStore from 'src/store/dialogStore'
import { DialogType, SampleInformationDialogType } from 'src/types/Dialog'
import { generateColumns } from './tablecolumns/PeptidesResultColumns'

function PeptidesResultTable({ searchTerm, searchType, hlaFilter }: { searchTerm: string, searchType: string, hlaFilter: string }) {
  const { isLoading, data, error } = usePeptides(searchType, searchTerm, hlaFilter)
  const fileId = useId()
  const filename = `alithea-peptides-export_${fileId}.csv`

  const { openDialog } = useDialogStore()
  const [ columns, setColumns ] = useState([])

  useEffect(() => {
    const openSampleInformationDialog = (sampleNumber: number) => {
      const sampleInformationDialog: SampleInformationDialogType = {
        type: DialogType.SampleInformation,
        payload: { sampleNumber }
      }

      openDialog(sampleInformationDialog)
    }

    setColumns(generateColumns(openSampleInformationDialog))
  }, [ data, openDialog ])

  if (isLoading) return <Loading label='Results are loading...' />

  if (error) {
    errorHandler(error)
  }

  if (data === undefined || data.length === 0) {
    return <NoResultMessageBox>There were no peptides found!</NoResultMessageBox>
  }

  return (
    <>
      <div className='mb-4 flex h-16 w-full items-center'>
        <h3 className='mr-auto'>Peptides</h3>
        <CsvDownloadButton data={data} filename={filename} />
      </div>
      <DataTable columns={columns} data={data} />
    </>
  )
}

export default PeptidesResultTable
