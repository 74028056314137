import React, { Dispatch, SetStateAction, useId } from 'react'
import { ResultTypeContext } from '../contexts/context'
import ResultType from '../../types/ResultType'
import CsvDownloadButton from '../offtarget/CsvDownloadButton'
import DownloadLinks from './DownloadLinks'
import { PeptideApiItem } from '../../types/responses/PeptideApiResponse'
import { ResultLink } from '../../types/responses/PlotApiResponse'
import PeptidesResultTable from './PeptidesResultTable'
import { SearchType } from '../../pages/Validation'
import Figures from './Figures'
import PeptideSamplesNewTable from './PeptidesSamplesTable'
import ProteinsResultTable from './ProteinsResultTable'
import PositionalPlot from './PositionalPlot'

interface Props {
  searchType: string
  validationResults: ValidationResults
  firstMatchedProteinId: string
    setFirstMatchedProteinId?: Dispatch<SetStateAction<string>>
}

interface ValidationResults {
  peptideResults: PeptideApiItem[]
  peptide: string
  resultLinks: ResultLink[]
  sampleSize: number
  resultLinksLoading: boolean
  peptideLoading: boolean
  hlaFilter: string
}

interface SectionProps {
  title?: string
  children: React.ReactNode
}

const Section = ({ title, children }: SectionProps) => (
  <div className='space-y-6 bg-white p-6 shadow-sm'>
    {title && (
      <div className='mb-4 flex h-16 w-full items-center'>
        <h3 className='mb-0 mr-auto'>{title}</h3>
      </div>
    )}

    {children}
  </div>
)

const PeptideResults = ({ validationResults, filename }) => (
  <>
    <Section title='Proteins'>
      <ResultTypeContext.Provider value={ResultType.ProteinsResult}>
        <ProteinsResultTable searchType={SearchType.peptide} searchTerm={validationResults.peptide} />
      </ResultTypeContext.Provider>
    </Section>

    <Section>
      <div className='mb-4 flex h-16 w-full items-center'>
        <h3 className='mr-auto'>Samples</h3>
        {validationResults.peptideResults.length > 0 && <CsvDownloadButton data={validationResults.peptideResults} filename={filename} />}
      </div>
      <ResultTypeContext.Provider value={ResultType.TargetValidationResult}>
        <PeptideSamplesNewTable loading={validationResults.peptideLoading} peptides={validationResults.peptideResults} />
      </ResultTypeContext.Provider>
    </Section>

    <Section title='Downloads'>
      <DownloadLinks sampleSize={validationResults.sampleSize} resultLinks={validationResults.resultLinks} loading={validationResults.resultLinksLoading} />
    </Section>
  </>
)

const ProteinResults = ({ validationResults, firstMatchedProteinId, setFirstMatchedProteinId }) => {

  return (
    <>
      <Section title='Proteins'>
        <ResultTypeContext.Provider value={ResultType.ProteinsResult}>
          <ProteinsResultTable searchType={SearchType.protein} searchTerm={validationResults.peptide} setFirstProteinId={setFirstMatchedProteinId} />
        </ResultTypeContext.Provider>
      </Section>

      <Section>
        <ResultTypeContext.Provider value={ResultType.TargetValidationResult}>
          <PeptidesResultTable searchType={SearchType.protein} searchTerm={validationResults.peptide}
            hlaFilter={validationResults.hlaFilter} />
        </ResultTypeContext.Provider>
      </Section>

      <Section title='Figures'>
        {firstMatchedProteinId != '' && <PositionalPlot proteinId={firstMatchedProteinId} hlaFilter={validationResults.hlaFilter} />}
        <Figures searchTerm={validationResults.peptide} searchType='peptide'
          hlaFilter={validationResults.hlaFilter} />
      </Section>
    </>
  )
}

const ValidationResult = ({ searchType, validationResults, firstMatchedProteinId, setFirstMatchedProteinId }: Props) => {
  const fileId = useId()
  const filename = `alithea-samples-export_${fileId}.csv`

  return (
    <div className='mt-6 space-y-6 border-t border-slate-200 pt-6'>
      {searchType === SearchType.peptide ? (
        <PeptideResults validationResults={validationResults} filename={filename} />
      ) : (
        <ProteinResults validationResults={validationResults} firstMatchedProteinId={firstMatchedProteinId} setFirstMatchedProteinId={setFirstMatchedProteinId} />
      )}
    </div>
  )
}

export default ValidationResult
