import Spinner from './Spinner'

type Props = {
  label: string
}

const Loading = ({ label }: Props) => {
  return (
    <div className='flex w-full place-content-center items-center bg-slate-600 px-6 py-10 text-white'>
      <div className='flex items-center'>
        <Spinner />
        {label && <span className='ml-4'>{label}</span>}
      </div>
    </div>
  )
}

export default Loading