import Loading from '../Loading'
import React from 'react'
import { usePositionalPlot } from '../../hooks/useProteinPlots'

function PositionalPlot({ proteinId, hlaFilter }: {proteinId: string, hlaFilter: string}) {
  const { isLoading, data } = usePositionalPlot(proteinId, hlaFilter)

  if (isLoading) return <Loading label='Results are loading...' />

  if (data === undefined || data.data === null) return <div>No plots generated.</div>

  return (
    <div className='mt-20 flex w-full max-w-screen-xl flex-col 2xl:max-w-screen-2xl'><img
      className='object-scale-down'
      src={`data:image/svg+xml;base64,${data.data}`} />
    </div>
  )

}

export default PositionalPlot