import { create, StoreApi, UseBoundStore } from 'zustand'
import PeptidesApiResponse from '../types/responses/PeptidesApiResponse'
import { SortBy, SortingDirection } from '../types/SortingDirection'
import { useContext } from 'react'
import { ResultTypeContext } from '../components/contexts/context'
import ResultType from '../types/ResultType'
import { DEFAULT_SORT_OF_PROPERTIES } from '../config/config'
import PeptideApiResponse from '../types/responses/PeptideApiResponse'

type ResultStore = {
  result?: PeptidesApiResponse | PeptideApiResponse
  setResult: (peptides: PeptidesApiResponse | PeptideApiResponse) => void
  loading: boolean
  setLoading: (loading: boolean) => void
  userInput: string[]
  setUserInput: (userInput: string[]) => void
  hlaFilter: string
  setHlaFilter: (hlaFilter: string) => void

  page: number
  setPage: (page: number) => void
  entriesPerPage: 10 | 20 | 25 | 50 | 100
  setEntriesPerPage: (amount: 10 | 20 | 25 | 50 | 100) => void

  sortingDirection: SortingDirection
  setSortingDirection: (sortingDirection: SortingDirection) => void
  sortBy: SortBy
  setSortBy: (sortBy: SortBy) => void

  reset: () => void
}

const createState = (set): ResultStore => ({
  result: null,
  setResult: result => set(() => ({ result })),
  loading: false,
  setLoading: loading => set(() => ({ loading })),
  userInput: [],
  setUserInput: userInput => set(() => ({ userInput })),
  hlaFilter: 'emptyFilter',
  setHlaFilter: hlaFilter => set(() => ({ hlaFilter })),

  page: 1,
  setPage: page => set(() => ({ page })),
  entriesPerPage: 25,
  setEntriesPerPage: entriesPerPage => set(() => ({ entriesPerPage })),

  sortingDirection: SortingDirection.Ascending,
  setSortingDirection: sortingDirection => set(() => ({ sortingDirection })),
  sortBy: SortBy.Peptide,
  setSortBy: sortBy => set(state => {
    if (sortBy === state.sortBy) {
      const sortingDirection = state.sortingDirection === SortingDirection.Ascending ? SortingDirection.Descending : SortingDirection.Ascending

      return { sortingDirection }
    }

    const sortingDirection = DEFAULT_SORT_OF_PROPERTIES[sortBy] || SortingDirection.Ascending

    return { sortingDirection, sortBy }
  }),

  reset: () => set(() => ({ result: null, page: 1 }))
})

const targetValidationResultStore: UseBoundStore<StoreApi<ResultStore>> = create(set => {
  const state = createState(set)

  state.sortBy = SortBy.Annotation

  return state
})

const offsetTableResultStore: UseBoundStore<StoreApi<ResultStore>> = create(set => {
  const state = createState(set)

  state.entriesPerPage = 20

  return state
})

const sampleListResultStore: UseBoundStore<StoreApi<ResultStore>> = create(set => {
  const state = createState(set)

  state.sortBy = SortBy.SampleNumber

  return state
})

const sampleInformationResultStore: UseBoundStore<StoreApi<ResultStore>> = create(set => createState(set))

const proteinsResultStore: UseBoundStore<StoreApi<ResultStore>> = create(set => {
  const state = createState(set)

  state.entriesPerPage = 10

  return state
})

const AvailableResultTypeStore: Record<ResultType, any> = {
  [ResultType.TargetValidationResult]: () => targetValidationResultStore(),
  [ResultType.OffTargetTableResult]: () => offsetTableResultStore(),
  [ResultType.SamplesListResult]: () => sampleListResultStore(),
  [ResultType.SampleInformationResult]: () => sampleInformationResultStore(),
  [ResultType.ProteinsResult]: () => proteinsResultStore(),
}

const useResultStore = () => {
  const resultType = useContext(ResultTypeContext)

  return AvailableResultTypeStore[resultType]()
}

export default <UseBoundStore<StoreApi<ResultStore>>> useResultStore