enum ApiPath {
  Plot = '/plot',
  PlotProteins = '/plotpy',
  Peptides = '/peptides',
  Categories = '/categories',
  Samples = '/samples',
  SimSearch = '/simsearch',
  Proteins = '/proteins',
  PositionalPlot = '/proteins/posplot'
}

export default ApiPath