import React, { BaseSyntheticEvent, useCallback, useEffect, useId, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { errorHandler, getPeptide, postSampleList } from '../client/apiClient'
import classNames from 'classnames'
import Scrollbar from '../components/Scrollbar'
import { ResultLink } from '../types/responses/PlotApiResponse'
import { PeptideApiItem } from '../types/responses/PeptideApiResponse'
import { ResultTypeContext } from '../components/contexts/context'
import ResultType from '../types/ResultType'
import ToOfftargetButton from '../components/discover/ToOfftargetButton'
import HlaFilter from '../components/HlaFilter'
import Select from '../components/Select'
import ValidationResult from '../components/discover/ValidationResult'

export enum SearchType {
  peptide = 'peptide',
  protein = 'protein',
}

function Validation() {
  const searchTypeOptions = [
    { key: 1, label: 'Peptide', value: SearchType.peptide },
    { key: 2, label: 'Protein/Gene', value: SearchType.protein },
  ]

  const [ searchParams, setSearchParams ] = useSearchParams()
  const searchTerm = searchParams.get('searchTerm')
  const [ searchType, setSearchType ] = React.useState(SearchType.peptide)

  const [ resultLinksLoading, setResultLinksLoading ] = useState(false)
  const [ peptideLoading, setPeptideLoading ] = useState(false)
  const [ resultLinks, setResultLinks ] = useState<ResultLink[]>([])
  const [ sampleSize, setSampleSize ] = useState(0)
  const [ peptideResults, setPeptideResults ] = useState<PeptideApiItem[]>([])
  const [ formSubmit, setFormSubmit ] = useState(false)
  const [ firstMatchedProteinId, setFirstMatchedProteinId ] = useState('')
  const peptideRef = useRef<HTMLInputElement>()
  const hlaFilterRef = useRef<HTMLInputElement>()
  const peptideId = useId()

  const resetResultList = () => {
    setResultLinks([])
    setPeptideResults([])
    setFirstMatchedProteinId('')
  }

  const loadSampleData = useCallback(
    async (searchType: string) => {
      if (resultLinksLoading || peptideLoading) return

      setResultLinksLoading(true)
      setPeptideLoading(true)
      setFormSubmit(false)
      resetResultList()

      if (searchType == SearchType.peptide) {
        const promises = [
          postSampleList(peptideRef.current.value, hlaFilterRef.current.value)
            .then(
              (response) => {
                setResultLinks(response.resultLinks)
                setSampleSize(response.sampleSize)
              },
              (reason) => {
                errorHandler(reason)
              }
            )
            .finally(() => setResultLinksLoading(false)),

          getPeptide(peptideRef.current.value, hlaFilterRef.current.value)
            .then((response) => setPeptideResults(response))
            .finally(() => setPeptideLoading(false)),
        ]
        setFormSubmit(true)
        await Promise.all(promises)
      } else {
        setResultLinksLoading(false)
        setPeptideLoading(false)
        setFormSubmit(true)
      }
    },
    [ resultLinksLoading, peptideLoading ]
  )

  useEffect(() => {
    setSearchType(SearchType[ searchParams.get('searchType') ])
    if ((searchTerm && !peptideRef.current.value) || (searchTerm && peptideRef.current.value != searchTerm)) {
      peptideRef.current.value = searchTerm
      loadSampleData(searchParams.get('searchType'))
    }
  }, [ searchTerm, searchParams, loadSampleData, searchType ])

  const onSubmit = async (event: BaseSyntheticEvent) => {
    event.preventDefault()

    const form = event.target
    const selectedSearchType = new FormData(form).get('selectedSearchType').toString()
    setSearchType(SearchType[selectedSearchType])

    setSearchParams({ searchType: selectedSearchType, searchTerm: peptideRef.current.value })
    await loadSampleData(selectedSearchType)
  }

  const isLoading = resultLinksLoading || peptideLoading

  return (
    <Scrollbar>
      <div className='mt-8 flex w-full max-w-screen-xl flex-col 2xl:max-w-screen-2xl'>
        <h2>Target Validation</h2>

        <form onSubmit={onSubmit}>
          <div className='flex w-full items-center gap-4'>
            <div>
              <label htmlFor={peptideId}>Search For:</label>
              <Select name='selectedSearchType' options={searchTypeOptions} hasAnyColumn={false} />
            </div>
            <div>
              <label htmlFor={peptideId}>Search Term:</label>
              <input type='text' placeholder='Please enter peptide' required id={peptideId} ref={peptideRef} />
            </div>
            <HlaFilter hlaFilterRef={hlaFilterRef} />
            <button
              type='submit'
              className={classNames('self-end', {
                'button-primary--active': !isLoading,
                'button-primary--disabled': isLoading,
              })}
            >
              Submit
            </button>
          </div>
        </form>

        {(isLoading || formSubmit) && (
          <ValidationResult
            searchType={searchType}
            validationResults={{
              peptideResults,
              peptide: searchTerm,
              resultLinks,
              sampleSize,
              resultLinksLoading,
              peptideLoading,
              hlaFilter: hlaFilterRef.current.value,
            }}
            firstMatchedProteinId={firstMatchedProteinId}
            setFirstMatchedProteinId={setFirstMatchedProteinId}
          />
        )}

        <ResultTypeContext.Provider value={ResultType.OffTargetTableResult}>
          <ToOfftargetButton />
        </ResultTypeContext.Provider>
      </div>
    </Scrollbar>
  )
}

export default Validation
