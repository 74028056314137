import useSamples from 'src/hooks/useSamples'
import Loading from '../Loading'
import { DataTable } from '../tables/DataTable'
import { generateColumns } from './Columns'
import useDialogStore from 'src/store/dialogStore'
import { DialogType, SampleInformationDialogType } from 'src/types/Dialog'
import { useEffect, useState } from 'react'

const SamplesResultsTable = () => {
  const { data, isLoading } = useSamples()
  const { openDialog } = useDialogStore()
  const [ columns, setColumns ] = useState([])

  useEffect(() => {
    const openSampleInformationDialog = (sampleNumber: number) => {
      const sampleInformationDialog: SampleInformationDialogType = {
        type: DialogType.SampleInformation,
        payload: { sampleNumber }
      }
  
      openDialog(sampleInformationDialog)
    }

    setColumns(generateColumns(openSampleInformationDialog))
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [ data ])

  if (isLoading) return <Loading label='Data is loading...' />

  return <DataTable columns={columns} data={data} />
}

export default SamplesResultsTable
