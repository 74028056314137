import React, { useEffect, useState } from 'react'
import Loading from '../Loading'
import { PeptideApiItem } from '../../types/responses/PeptideApiResponse'
import useDialogStore from '../../store/dialogStore'
import { DialogType, SampleInformationDialogType } from '../../types/Dialog'
import NoResultMessageBox from '../NoResultMessageBox'
import { DataTable } from '../tables/DataTable'
import { generateColumns } from './tablecolumns/PeptidesSamplesColumns'

type Props = {
  loading?: boolean
  peptides: PeptideApiItem[]
}

const PeptideSamplesNewTable = ({ peptides, loading = false }: Props) => {
  const { openDialog } = useDialogStore()
  const [ columns, setColumns ] = useState([])

  const hasPeptides = peptides.length > 0

  useEffect(() => {
    const openSampleInformationDialog = (sampleNumber: number) => {
      const sampleInformationDialog: SampleInformationDialogType = {
        type: DialogType.SampleInformation,
        payload: { sampleNumber }
      }
  
      openDialog(sampleInformationDialog)
    }

    setColumns(generateColumns(openSampleInformationDialog))
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [ peptides ])

  if (loading) return <Loading label='Results are loading...' />
  if (!hasPeptides) return <NoResultMessageBox>There were no samples found!</NoResultMessageBox>

  return <DataTable columns={columns} data={peptides} />
}

export default PeptideSamplesNewTable