import { Button } from 'src/@/components/ui/button'
import Sample from 'src/types/Sample'
import { ColumnDef, SortingFn } from '@tanstack/react-table'
import { ArrowUpDown } from 'lucide-react'
import { Link } from 'react-router-dom'
import Route from '../../../config/route'

const CustomHeaderButton: React.FC<{
  columnName: string
  column: any
  classNameProp: string

}> = ({ columnName, column, classNameProp }) => (
  <Button
    variant='ghost'
    onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
    className={`${classNameProp} cursor-pointer py-3 pl-4`}

  >
    <span className='text-left'>{columnName}</span>
    <ArrowUpDown className='ml-1 h-4 w-4' />
  </Button>
)

const sortColumnsFn: SortingFn<Sample> = (rowA, rowB, columnId) => {
  const propA = rowA.original[columnId]
  const propB = rowB.original[columnId]

  if (typeof propA === 'number' && typeof propB === 'number') {
    return propA - propB
  }

  return propA.localeCompare(propB)
}

const columnNames = [
  'sample_number',
  'peptide',
  'sample_class',
  'sample_name',
  'sample_hla_typing',
  'best_predicted_binder',
  'normalized_intensity',
]  

const columnMapping: Record<
  string,
  { headerText: string, classNameProp: string}
> = {
  sample_number: { headerText: 'Sample Number', classNameProp: 'w-40 shrink' },
  peptide: { headerText: 'Peptide', classNameProp: 'w-72 shrink' },
  sample_class: { headerText: 'Sample Class', classNameProp: 'w-48 shrink' },
  sample_name: { headerText: 'Sample Name', classNameProp: 'w-56 shrink' },
  sample_hla_typing: { headerText: 'Sample HLA Typing', classNameProp: 'w-96 shrink' },
  best_predicted_binder: { headerText: 'Best Predicted Binder', classNameProp: 'w-56 shrink' },
  normalized_intensity: { headerText: 'Normalized Intensity', classNameProp: 'w-52 shrink' },
}

export const generateColumns = (
  openSampleInformationDialog
): ColumnDef<Sample>[] => {
  return columnNames.map((columnName) => ({
    accessorKey: columnName,

    header: ({ column }) => (
      <CustomHeaderButton
        columnName={columnMapping[columnName].headerText}
        column={column}
        classNameProp={columnMapping[columnName].classNameProp}

      />
    ),

    cell: ({ row }) => {
      const peptideProperty = row.original[columnName]
      switch (true) {
        case columnName === 'sample_number':
          return (
            <div
              className='link px-5 py-3'
              onClick={() => openSampleInformationDialog(peptideProperty)}
            >
              {peptideProperty}
            </div>
          )

        case columnName === 'normalized_intensity':
          return (
            <div className='px-5 py-3'>
              {Intl.NumberFormat(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(peptideProperty)}
            </div>
          )

        case columnName === 'peptide':
          return <Link className='px-5 py-3' to={`${Route.Discover}?searchType=peptide&searchTerm=${peptideProperty}`} reloadDocument>{peptideProperty}</Link>

        default:
          return <div className='px-5 py-3'>{peptideProperty}</div>
      }
    },
    sortingFn: sortColumnsFn,
  }))
}
