import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Loading from '../Loading'
import useProteins from '../../hooks/useProteins'
import NoResultMessageBox from '../NoResultMessageBox'
import { DataTable } from '../tables/DataTable'
import { generateColumns } from './tablecolumns/ProteinResultColumns'

type Props = {
  searchType: string
  searchTerm: string
  setFirstProteinId?: Dispatch<SetStateAction<string>>
}

const ProteinsResultTable = ({ searchType, searchTerm, setFirstProteinId }: Props) => {
  const { isLoading, data } = useProteins(searchType, searchTerm)
  const [ columns, setColumns ] = useState([])

  useEffect(() => {
    if (!data) return
    setColumns(generateColumns())
  }
  , [ data ])

  if (isLoading) return <Loading label='Results are loading...' />
  if (data == undefined || data.length == 0) return <NoResultMessageBox>There was no protein information found!</NoResultMessageBox>

  if (! (undefined == setFirstProteinId)) setFirstProteinId(data[0].protein_id)

  return <DataTable columns={columns} data={data} />
}

export default ProteinsResultTable