import Loading from '../Loading'
import React from 'react'
import useProteinPlots from '../../hooks/useProteinPlots'

function ProteinPlots({ searchTerm, searchType, hlaFilter }: {searchTerm: string, searchType: string, hlaFilter: string}) {
  const { isLoading, data } = useProteinPlots(searchType, searchTerm, hlaFilter)

  if (isLoading) return <Loading label='Results are loading...' />

  if (data.resultLinks === null) return <div>No plots generated.</div>

  return (
    <div>
      {data.resultLinks.map((resultLink, index) => {
        if (resultLink === null) return

        return (
          <div className='mt-20 flex w-full max-w-screen-xl flex-col 2xl:max-w-screen-2xl' key={index}><img
            className='object-scale-down'
            src={`data:image/svg+xml;base64,${resultLink.data}`} />
          </div>
        )
      })}
    </div>
  )

}

export default ProteinPlots