import useDialogStore from '../store/dialogStore'
import PerfectScrollbar from 'react-perfect-scrollbar'
import DialogConfig from '../config/dialog'
import useDialog from '../hooks/useDialog'

const DialogContainer = () => {
  const { closeDialog } = useDialogStore()
  const dialog = useDialog()

  if (!dialog) return null

  const dialogContentElement = DialogConfig[dialog.type]
  const payload = dialog.payload ?? {}

  if (payload?.title) dialogContentElement.title = payload?.title
  if (payload?.subtitle) dialogContentElement.subtitle = payload?.subtitle

  return (
    <div className='fixed inset-0 z-[100] h-full w-full overflow-y-auto overflow-x-hidden outline-none' tabIndex={-1} aria-labelledby='modallabel' aria-modal='true' role='dialog'>
      <div className='flex max-h-screen min-h-screen flex-col items-center justify-center'>
        <div className='fixed inset-0 bg-slate-700/80 backdrop-blur-sm transition-opacity' onClick={closeDialog} />

        <div className='pointer-events-auto relative flex max-h-screen w-full max-w-4xl flex-col bg-white bg-clip-padding shadow-lg outline-none'>
          <div className='flex shrink-0 items-center justify-between border-b border-slate-200 bg-sky-500 px-8 py-4'>
            <div className='flex items-center text-xl font-medium leading-normal text-white'>
              {dialogContentElement.icon}
              <h4 id='modallabel' className='mb-0'>{dialogContentElement.title}</h4>
            </div>
            <button type='button' onClick={closeDialog} className='h-auto border-none p-0 text-2xl font-bold text-white focus:shadow-none focus:outline-none' aria-label='Close'>&times;</button>
          </div>
          <PerfectScrollbar className='p-8'>
            {dialogContentElement.subtitle && <div className='mb-8'>{dialogContentElement.subtitle}</div>}
            {dialogContentElement.content}
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  )
}

export default DialogContainer