const getLinkExpirationTime = (link: string): Date => {
  const regex = new RegExp(/X-Amz-Expires=(\d+)/, 'g')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const result = regex.exec(link)

  if (result && result[1]) {
    const expirationInSeconds = result[1]
    const expiryDate = new Date(Date.now())

    expiryDate.setSeconds(expiryDate.getSeconds() + parseInt(expirationInSeconds))

    return expiryDate
  }

  return null
}

export default getLinkExpirationTime