import { useMemo } from 'react'
import { SortBy, PeptideSorting, SortingDirection } from '../types/SortingDirection'
import useResultStore from '../store/resultStore'
import PeptideApiResponse, { PeptideApiItem } from '../types/responses/PeptideApiResponse'

const useSortedPeptides = (result: PeptideApiResponse): [ PeptideApiResponse, boolean ] => {
  const { sortBy, sortingDirection, loading } = useResultStore()
  const sortingFunction = PeptideSorting[sortBy]

  const sortedPeptides = useMemo(() => {
    if (!sortingFunction) return result
    if (!result || loading) return []

    return sortPeptides(result, sortBy, sortingDirection)
  }, [ result, sortBy, sortingDirection, loading, sortingFunction ])

  return [ sortedPeptides, loading ]
}

const sortPeptides = (peptides: PeptideApiResponse, sortBy: SortBy, sortingDirection: SortingDirection) => {
  return peptides.sort((a, b) => {
    const peptideListSort = () => peptideListDefaultSort(sortBy, a, b)

    return (sortingDirection === SortingDirection.Descending) === peptideListSort() ? 1 : -1
  })
}

const peptideListDefaultSort = (sortBy: SortBy, a: (PeptideApiItem), b: (PeptideApiItem)) => PeptideSorting[sortBy](a, b)

export default useSortedPeptides