import { ResultLink } from '../../types/responses/PlotApiResponse'
import React from 'react'
import getLinkExpirationTime from '../../lib/getLinkExpirationTime'
import Loading from '../Loading'
import NoResultMessageBox from '../NoResultMessageBox'

interface Props {
  sampleSize: number
  resultLinks: ResultLink[]
  loading?: boolean
}

const DownloadLinks = ({ sampleSize, resultLinks, loading = false }: Props) => {
  if (loading) return <Loading label='Creating download links. This may take a while...' />
  if (sampleSize <= 0) return <NoResultMessageBox>There were no samples found!</NoResultMessageBox>

  return (
    <ul className='list-inside list-disc'>
      {resultLinks.map((resultLink, index) => {
        const { filename, link } = resultLink
        const expirationTime = getLinkExpirationTime(link)

        if (!link || !expirationTime) return null

        return (
          <li key={index} className='mb-2 last:mb-0'>
            <a href={link} target='_blank' rel='noreferrer'>
              {`${filename} (valid until ${expirationTime.toLocaleTimeString()})`}
            </a>
          </li>
        )
      })}
    </ul>
  )
}

export default DownloadLinks
